<template>
  <div class="pushs-create">
    <Banner>
      <div class="banner-content">
        <MeepIconChat class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.chat") }}</span>
      </div>
    </Banner>
    <div class="pushs-create-content page-layout">
      <md-card class="md-layout-item">
        <PageHeader
          :has-back="true"
          title="Envoyer une notification mobile"
          @back="goBack"
        />
        <!-- le formulaire de création -->
        <md-card-content>
          <!-- Groupes -->
          <md-chips
            v-model="form.groupes"
            md-placeholder="Ajouter des groupes"
            onkeydown="return false;"
            onpaste="return false;"
            @click.native="openSelectGroupesModal"
          >
            <template slot="md-chip" slot-scope="{ chip }"
            >{{ chip.name }}
            </template>
            <div class="md-helper-text">{{ $t("push.group") }}</div>
          </md-chips>

          <!-- Message à envoyer -->
          <md-field
            :class="{ 'md-invalid': errors.has('message') }"
            class="pushs-create__textarea"
          >
            <label>Message à envoyer *</label>
            <md-textarea
              v-model="form.message"
              v-validate="'required'"
              name="message"
            />
            <span v-show="errors.has('message')" class="md-error">{{
                errors.first("message")
              }}</span>
          </md-field>

          <!-- Date d'envoi -->

          <md-switch v-model="showDate">
            {{ $t("meep-form.date-text") }}
          </md-switch>

          <md-datepicker
            v-if="showDate"
            v-model="form.date"
            :md-disabled-dates="isDateInvalid"
          >
            <label>
              Date d'envoi *
            </label>
          </md-datepicker>

          <md-switch v-if="showDate" v-model="form.sendLater">
            {{ $t("meep-form.hour-text") }}
          </md-switch>
          <div v-if="form.sendLater" class="meep-input meep-time">
            <label class="meep-label"> Heure d'envoi </label>

            <vue-timepicker v-model="form.hour" format="HH:mm" />
          </div>

          <div class="md-layout md-alignment-center-right">
            <md-button class="md-raised md-primary" @click.native="sendPush">
              {{ $t("pushes.button-text") }}
            </md-button>
          </div>
        </md-card-content>
      </md-card>

      <select-items-modal
        v-if="isSelectGroupesModalOpen"
        :items-list="groupesCatalog"
        :selected-list="form.groupes"
        :text="selectGroupesModalText"
        @close="closeSelectGroupesModal"
        @saved="onGroupesSelected"
      >
        <template #icon>
          <MeepIconSettingGroupes class="app-icon-color" />
        </template>
      </select-items-modal>
    </div>
  </div>
</template>

<script>
/* Modèles */
import groupesModel from "../../model/groupes";
import pushsModel from "../../model/pushs";

/* Composants */
import VueTimepicker from "vue2-timepicker";
import selectItemsModal from "../../components/modal/select-items";
import PageHeader from "@/components/PageHeader";
import Banner from "@/components/Banner";
import MeepIconChat from "@/components/icons/MeepIconChat.vue";
import MeepIconSettingGroupes from "@/components/icons/MeepIconSettingGroupes.vue";

export default {
  components: {
    PageHeader,
    "select-items-modal": selectItemsModal,
    "vue-timepicker": VueTimepicker,
    Banner,
    MeepIconChat,
    MeepIconSettingGroupes,
  },
  data() {
    return {
      /* Formulaire */
      form: {
        groupes: [],
        message: null,
        date: new Date(),
        sendLater: false,
        hour: {
          HH: "00",
          mm: "00",
        },
      },

      /* Modal */
      showDate: false,
      selectGroupesModalText: {
        header: "Sélectionner les groupes",
      },
      groupesCatalog: [],
      isSelectGroupesModalOpen: false,
    };
  },

  methods: {
    goBack() {
      window.history.back();
    },

    isDateInvalid(date) {
      const today = new Date();

      if (this.form.sendLater) {
        date.setHours(this.form.hour.HH, this.form.hour.mm);
      } else {
        today.setHours(0, 0, 0, 0);
      }
      return date < today;
    },

    sendPush() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });

          return;
        }

        if (this.isDateInvalid(this.form.date)) {
          this.$toasted.global.AppError({
            message:
              "Vous ne pouvez pas choisir une date et une heure antérieur à maintenant",
          });

          return;
        }

        pushsModel
          .send(this.form)
          .then(() => {
            this.$toasted.global.AppSucces({
              message: this.$t("push.send-success"),
            });

            this.$router.push("/dashboard/communicate/pushs/");
          })
          .catch(err => {
            this.$toasted.global.AppError({
              message: err.msg,
            });
          });
      });
    },

    /* ## MODAL ##  */

    openSelectGroupesModal() {
      groupesModel
        .getAll()
        .then(groupes => {
          this.groupesCatalog = groupes;
          this.isSelectGroupesModalOpen = true;
        })
        .catch(err => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },
    closeSelectGroupesModal() {
      this.isSelectGroupesModalOpen = false;
    },
    onGroupesSelected(groupes) {
      this.form.groupes = groupes;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/variable.scss";

#app {
  .pushs-create {
    .page-header {
      .md-theme-default {
        .md-icon.md-primary {
          width: var(--back-button-size-lg);
          height: var(--back-button-size-lg);
          border-radius: 50%;

          @include for-lg {
            width: var(--back-button-size-xl);
            height: var(--back-button-size-xl);
          }
        }
      }
    }

    &-content {
      padding: 0px 44px 90px 54px;

      .md-card {
        max-width: 770px;
        padding: 40px 50px 45px 50px;
        max-height: 750px;
        box-shadow: none;
        margin-left: 30px;

        .md-card-content {
          padding: 0;

          .md-primary {
            padding: 12px 28px;
          }

          .md-chip {
            &.md-deletable {
              .md-clear {
                background: #000;
                height: 18px;
                padding: 0;
              }
            }

            &:hover {
              path:nth-child(1) {
                fill: #fff;
              }
            }
          }

          .md-field {
            background: #f2f5f7;
            border-radius: 9px;
            margin: 0;

            &:after,
            &:before {
              display: none;
            }

            &:first-child {
              padding: 0 0 0 toRem(12);
              align-items: center;

              .md-chip {
                margin-bottom: 0;
              }
            }
          }

          .md-datepicker {
            background: #f2f5f7;
            margin-top: 0px;
            height: 47px;
            @include for-lg {
              height: 66px;
              padding-top: 20px;
            }

            .md-date-icon {
              display: none;
            }

            .md-button.md-clear {
              padding: 0;
              min-width: unset;
              width: 31px;
              height: 31px;
              @include for-lg {
                width: 43px;
                height: 43px;
              }
            }

            input {
              font-size: toRem(13);
              @include for-lg {
                font-size: toRem(17);
              }
            }

            label {
              top: 15px;
              left: 12px;
              display: block;
            }

            &.md-has-value {
              label {
                top: 5px;
              }
            }
          }

          .md-alignment-center-right {
            button {
              font-size: toRem(9);
              padding: 0;
              @include for-lg {
                font-size: toRem(14);
              }
            }
          }
        }

        .page-header {
          &__item {
            padding: 0;
            margin: 0;
          }
        }

        .md-field {
          .md-textarea {
            min-height: 120px;
          }

          &.pushs-create__textarea {
            margin-top: 55px;
          }
        }

        .meep-time {
          padding: 0 toRem(12);
          height: 48px;
          display: flex;
          align-items: center;
          @include for-lg {
            height: 50px;
          }

          .time-picker {
            margin-top: toRem(15);

            input {
              font-size: toRem(12);
              @include for-lg {
                font-size: toRem(17);
              }
            }
          }

          label {
            position: absolute;
            top: 5px;
            font-size: toRem(12);
          }
        }
      }

      .md-helper-text {
        top: 55px;
        left: 5px;
        font-size: toRem(11);
        font-family: var(--font-x);
        color: black;
        @include for-lg {
          font-size: toRem(13);
        }
      }
    }
  }
}
</style>
